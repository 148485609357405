// placing this logic here, in case I need to use it
// since it was very complicated to work out. 
// ************     TAKE CONTROL FUNCTIONALITY     *************
// let joinRoom = (name, announce) => {
//     const userName = name
//     let roomName
//     let presentationName
//     let ownerIsMe = false
//     let myName = name

//     // grab the URL parameters to get the dynamic room name
//     let theRoom = getRoom()
//     roomName = theRoom

//     // if not an actual room, just return
//     if (roomName === "invalid room") {
//         alert('not a valid room')
//         dataLayer.push({
//             event: "roomError",
//             errorType: "Invalid Room"
//         });
//         return false
//     }

//     // fire off an event to gtm
//     dataLayer.push({
//         event: "joinRoom",
//         roomName: roomName
//     })

//     // Now we join the room that the user has shared with us
//     socket.emit('room', roomName)

//     // hide the form and show the controls
//     let theForm = document.querySelector('#roomForm')
//     let theControls = document.querySelector('#controls')
//     theForm.classList.add("hide")
//     theControls.classList.remove("hide")

//     showMyName(myName)

//     // attach events to the advance buttons
//     let advancePresentation = (event) => {
//         let buttonId = event.target.id
//         if (buttonId === 'presentation_previous' || buttonId === 'presentation_next') {
//             let span = event.target.querySelectorAll('.direction')
//             let direction = span[0].innerText.toString().toLowerCase();

//             if (socket.connected === true) {
//                 // clear out error message
//                 clearError()

//                 // emit this event to the dynamic room
//                 socket.emit(roomName, {
//                     "type": "control",
//                     "direction": direction,
//                     "roomName": roomName
//                 }, function (response) {
//                     // response should be 'ok'
//                     // if not, it means socket server went down
//                     if (response !== 'ok') {
//                         displayError('Connectivity error, please try again.')
//                     } else {
//                         clearError()
//                     }

//                 });

//             } else {
//                 displayError('Connectivity error, please try again.')
//             }

//             event.preventDefault()

//             return false
//         }

//     }
//     document.documentElement.addEventListener("click", advancePresentation, false)
//     document.documentElement.addEventListener("touchend", advancePresentation, false)

//     socket.on(roomName, function (msg) {
//         // when I get a meta event from the socket show that info to the user
//         if (msg.type === 'meta') {
//             if (!presentationName) {
//                 presentationName = msg.presentationName
//                 showPresentationName(presentationName)
//             }
//         }

//         // *********   DISABLED     *************
//         // changeOwner message could mean someone is taking ownership or they 
//         // are releasing ownership, indicated by a blank msg.owner property.
//         // in either case, I am not taking ownership, so reset everything
//         // if (msg.type === "changeOwner") {
//         //     changeOwner(msg.owner, false)
//         // }

//     })

//     // STORING STATE AND MAKING SURE WE DO NOT LOSE A CONNECTION

//     // If this is the first time joining, announce this user and save data
//     // to allow for a page refresh
//     if (announce) {

//         // announce this user to the room
//         socket.emit(roomName, {
//             "type": "add user",
//             "userName": userName,
//             "roomName": roomName
//         })

//     }

//     // store the room to allow a page refresh
//     rememberRoom(roomName, myName)

//     // Various events for handling connection issues and displaying them to the user
//     socket.on('disconnect', function () {
//         displayError('Connection interrupted. Will attempt to reconnect.')
//         togglePresentationButtons('disable')
//     });
//     // attempting to reconnect
//     socket.on('reconnecting', function (nextRetry) {
//         displayError('Connection is lost, reconnecting...')
//         togglePresentationButtons('disable')
//     });
//     // reconnect failed
//     socket.on('reconnect_failed', function () {
//         displayError("Reconnect failed.")
//         togglePresentationButtons('disable')
//     });
//     // connection restored  
//     socket.on('reconnect', function () {
//         // re-join the private room on re-connect
//         socket.emit('room', roomName)
//         displayError("Connection re-established.")
//         togglePresentationButtons('enable')
//         clearError()
//     });
//     // socket error  
//     socket.on('error', function () {
//         displayError("Sorry, there has been an error.")
//         togglePresentationButtons('disable')
//     });

//     // enable and disable the presentation controls
//     let togglePresentationButtons = (action) => {
//         let presentationControls = document.getElementById('presentation_controls')
//         let buttons = presentationControls.getElementsByTagName('button')
//         if (action === 'disable') {
//             for (const element of buttons) {
//                 element.disabled = true
//             }
//         }
//         if (action === 'enable') {
//             for (const element of buttons) {
//                 element.disabled = false
//             }
//         }
//     }

//     // ************      THIS FUNCTIONALITY IS CURRENTLY DISABLED       *******************
//     // // take control of the presentation and disable the buttons for everyone else
//     // let takeControl = (event) => {
//     //     let buttonId = event.target.id
//     //     // Anyone can take control at any time, so you are never prevented from clicking this button.
//     //     // So, there are 3 states:
//     //     //       - I have taken control by clicking this button
//     //     //       - I have released control by clicking this button
//     //     //       - Someone has taken control from me (handled in socket message type of 'changeOwner')
//     //     if (buttonId === 'take_control') {

//     //         // if I am owner, release it. if not takeownership
//     //         if (ownerIsMe) {
//     //             changeOwner('', false)
//     //             // notify everyone I am releasing control
//     //             socket.emit(roomName, {
//     //                 "type": "changeOwner",
//     //                 "owner": ""
//     //             })
//     //         } else {
//     //             changeOwner('', true)
//     //             // notify everyone I am taking over
//     //             socket.emit(roomName, {
//     //                 "type": "changeOwner",
//     //                 "owner": myName
//     //             })
//     //         }

//     //         event.preventDefault()
//     //         return false
//     //     }
//     // }
//     // document.documentElement.addEventListener("click", takeControl, false)
//     // document.documentElement.addEventListener("touchend", takeControl, false)

//     // // display the current owner to the user
//     // let displayOwnerName = (owner) => {
//     //     let ownerDisplay = document.getElementById('ownerName')
//     //     if (owner !== '') {
//     //         let person = (owner === 'me') ? "You have " : owner + " has "
//     //         ownerDisplay.innerText = person + " taken control of the presentation."
//     //     } else {
//     //         ownerDisplay.innerText = ""
//     //     }
//     // }

//     // // state management of the control button
//     // let toggleControlButton = (state) => {
//     //     let controlButton = document.getElementById('take_control')
//     //     if (state === "take") {
//     //         controlButton.innerText = "Release Control"
//     //     }
//     //     if (state === "give") {
//     //         controlButton.innerText = "Take Control"
//     //     }
//     // }

//     // // logic for presentation ownership: someone else, me, or nobody
//     // let changeOwner = (name, me) => {
//     //     if (name !== '' && !me) { //someone else
//     //         ownerIsMe = false
//     //         toggleControlButton('give')
//     //         togglePresentationButtons('disable')
//     //         displayOwnerName(name)
//     //         return
//     //     }
//     //     if (me) { // me
//     //         ownerIsMe = true
//     //         toggleControlButton('take')
//     //         togglePresentationButtons('enable')
//     //         displayOwnerName('me')
//     //     } else { // no one
//     //         ownerIsMe = false
//     //         toggleControlButton('give')
//     //         togglePresentationButtons('enable')
//     //         displayOwnerName('')
//     //     }
//     //     return
//     // }
// }