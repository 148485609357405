import * as Utils from './utils.js'

// join private room
const joinRoom = (name, announce) => {
    const socket = io()
    const userName = name
    let roomName
    let presentationName
    let myName = name

    // grab the URL parameters to get the dynamic room name
    let theRoom = Utils.getRoom()
    roomName = theRoom

    // if not an actual room, just return
    if (roomName === "invalid room") {
        alert('not a valid room')
        dataLayer.push({
            event: "roomError",
            errorType: "Invalid Room"
        });
        return false
    }

    // Now we join the room that the user has shared with us
    socket.emit('room', roomName)

    // hide the form, show the controls, update myName in UI
    Utils.swapViews('controls')
    Utils.showMyName(myName)

    // attach events to the advance buttons
    let advancePresentation = (event) => {
        let buttonId = event.target.id

        if (buttonId === 'presentation_previous' || buttonId === 'presentation_next') {

            if (event.target.disabled) return false

            let span = event.target.querySelectorAll('.direction')
            let direction = span[0].innerText.toString().toLowerCase();

            if (socket.connected === true) {
                // clear out any error message
                Utils.clearError()

                // emit this event to the dynamic room
                socket.emit(roomName, {
                    "type": "control",
                    "direction": direction,
                    "roomName": roomName,
                    "user": myName
                }, function (response) {
                    // response should be 'ok'
                    // if not, it means socket server went down
                    if (response !== 'ok') {
                        Utils.displayError('Connectivity error, please try again.')
                    } else {
                        Utils.clearError()
                    }

                });

            } else {
                Utils.displayError('Connectivity error, please try again.')
            }

            event.preventDefault()

            return false
        }

    }
    document.documentElement.addEventListener("click", advancePresentation, false)
    document.documentElement.addEventListener("touchend", advancePresentation, false)

    socket.on(roomName, function (msg) {
        // when I get a meta event from the socket show that info to the user
        if (msg.type === 'meta') {
            if (!presentationName) {
                presentationName = msg.presentationName
                Utils.showPresentationName(presentationName)
            }
        }

    })

    // If this is the first time joining, announce this user and save data
    // to allow for a page refresh
    if (announce) {

        // announce this user to the room
        socket.emit(roomName, {
            "type": "add user",
            "userName": userName,
            "roomName": roomName
        })

    }

    // store the room to allow a page refresh
    Utils.rememberRoom(roomName, myName)

    // fire off an event to gtm
    dataLayer.push({
        event: "joinRoom",
        roomName: roomName
    })

    // Various events for handling connection issues and displaying them to the user
    socket.on('disconnect', function () {
        Utils.displayError('Connection interrupted. Will attempt to reconnect.')
        Utils.togglePresentationButtons('disable')
    });
    // attempting to reconnect
    socket.on('reconnecting', function (nextRetry) {
        Utils.displayError('Connection is lost, reconnecting...')
        Utils.togglePresentationButtons('disable')
    });
    // reconnect failed
    socket.on('reconnect_failed', function () {
        Utils.displayError("Reconnect failed.")
        Utils.togglePresentationButtons('disable')
    });
    // connection restored  
    socket.on('reconnect', function () {
        // re-join the room
        socket.emit('room', roomName)

        Utils.displayError("Connection re-established.")
        Utils.togglePresentationButtons('enable')
        Utils.clearError()
    });
    // socket error  
    socket.on('error', function () {
        Utils.displayError("Sorry, there has been an error.")
        Utils.togglePresentationButtons('disable')
    });
}

// Init for Private Room Page

// handle form submit
const joinRoomEvent = (event) => {

    if (event.target.id === 'join-room') {
        let userNameInput = document.querySelector('#user-name')
        let userName = userNameInput.value.trim()
        joinRoom(userName, true);
        event.preventDefault();
    }

}

// page load
const pageLoad = () => {
    let theRoom = Utils.getRoom()
    let savedRoom = Utils.checkRoom(theRoom)

    // rejoin the previously joined room
    if (savedRoom && theRoom === savedRoom.roomName) {
        joinRoom(savedRoom.userName, false)
    } else {
        // show the form 
        let theForm = document.querySelector('#roomForm')
        theForm.classList.remove("hide")
    }

    document.documentElement.addEventListener("click", joinRoomEvent, false)
    document.documentElement.addEventListener("touchend", joinRoomEvent, false)
}
const privateRoom = document.querySelector('#privateRoom')
if (privateRoom !== null) window.addEventListener('load', pageLoad);