const axios = require('axios')

window.onload = function() {
    const acceptTerms = document.getElementById('acceptTerms')
  
    if(acceptTerms !== null) {

        const button = document.getElementById('downLoadLink')

        // handle initial load if it is already checked
        if(acceptTerms.checked) {
            button.disabled = false
        }
        
        acceptTerms.addEventListener('change', (event) => {
            if (event.currentTarget.checked) {
              button.disabled = false
            } else {
              button.disabled = true
            }
          })

        // handle download button click
        button.addEventListener('click', (e) => {
            e.preventDefault()

            window.open('/download/acceptTerms', '_blank')
            window.open('/download/finished', '_self')

        })
  
    }
  }