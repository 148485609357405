// *** GLOBAL METHODS ***
const sanitize = (string) => {
    const map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        "/": '&#x2F;',
    };
    const reg = /[&<>"'/]/ig;
    return string.replace(reg, (match) => (map[match]));
}

export const getRoom = () => {
    let urlParams = new URLSearchParams(window.location.search)
    let queryString = urlParams.get('r')
    if (!queryString || !queryString.length) {
        return "invalid room"
    }
    let safeString = sanitize(queryString)
    return safeString
}

export const showPresentationName = (name) => {
    let presentationEl = document.querySelector('#presentationName')
    presentationEl.innerHTML = "(" + name + ")"
}

export const showMyName = (name) => {
    let nameDisplay = document.getElementById('my_name')
    nameDisplay.innerText = name
}

export const displayError = (msg) => {
    let message = document.getElementById('errorMessage')
    let container = message.parentNode
    let connectionStatus = document.getElementById('connectionStatus')
    message.innerText = msg
    container.classList.remove('hidden')
    connectionStatus.innerText = 'Disconnected'
    connectionStatus.classList.replace('text-green-900', 'text-baton-red')
}

export const clearError = () => {
    let message = document.getElementById('errorMessage')
    let container = message.parentNode
    let connectionStatus = document.getElementById('connectionStatus')
    message.innerText = ''
    container.classList.add('hidden')
    connectionStatus.innerText = 'Active'
    connectionStatus.classList.replace('text-baton-red', 'text-green-900')
}

export const rememberRoom = (roomName, name) => {
    let today = new Date();
    let timeStamp = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate()
    // we only care about today and we don't care about any other rooms they 
    // may have joined today. so just set a localStorage item with today's date
    // and the string of the room
    if (roomName !== "") {
        let data = {
            'roomName': roomName,
            'userName': name
        }
        localStorage.setItem(timeStamp, JSON.stringify(data));

    }
}

export const checkRoom = (roomName) => {
    let today = new Date();
    let timeStamp = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDate()
    let data = JSON.parse(localStorage.getItem(timeStamp))

    return data
}

export const swapViews = (view) => {
    let theForm = document.querySelector('#roomForm')
    let theControls = document.querySelector('#controls')
    if (view === 'controls') {
        theForm.classList.add("hide")
        theControls.classList.remove("hide")
    }
    if (view === 'form') {
        theForm.classList.remove("hide")
        theControls.classList.add("hide")
    }

}

// enable and disable the presentation controls
export const togglePresentationButtons = (action) => {
    let presentationControls = document.getElementById('presentation_controls')
    let buttons = presentationControls.getElementsByTagName('button')
    if (action === 'disable') {
        for (const element of buttons) {
            element.disabled = true
        }
    }
    if (action === 'enable') {
        for (const element of buttons) {
            element.disabled = false
        }
    }
}