<script>
  let email = "";
  let name = "";
  let message = "";
  let submit = false;
  let formErrors = false;
  let serverErrors = false;

  function handleForm() {
    let myName = name;
    let myEmail = email;
    let myMessage = message;
    let emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      myEmail
    );
    let nameValid = myName.length > 0;
    let messageValid = myMessage.length > 0;

    if (emailValid && nameValid && messageValid) {
      // data is valid, so send the data to the server
      let route = "/contact-us/contactForm";
      let data = {
        email: myEmail,
        name: myName,
        message: myMessage,
        key: "1b0e1522-361d-454d-bd72-9942fcfc54f0" // this is my security for the AJAX form. the route is expecting this.
      };

      fetch(route, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          // submit is true and UI will update automatically
          submit = true;
          // fire custom GA event for successful form submit
          dataLayer.push({ event: "formSubmitted", formName: "Contact Us" });
        })
        .catch(error => {
          serverErrors = true;
          submit = false;
        });
    } else {
      // they didn't supply all the required fields or their email is not in a valid format
      formErrors = true;
      submit = false;
    }
  }
</script>

<h2 class="text-5xl mb-6">Contact Us</h2>
{#if !submit}
  <p class="text-lg">
    Use the form below to contact the team at Baton. All fields are required.
  </p>

  {#if formErrors}
    <p class="text-baton-red mb-4 text-lg">
      You have errors in your form, please correct them and try again.
    </p>
  {/if}
  {#if serverErrors}
    <p class="text-baton-red mb-4 text-lg">
      We are experiencing server errors. You may want to try again later. Sorry
      for the hassle.
    </p>
  {/if}

  <form class="w-full max-w-md pt-6" action="#" id="contact-form">
    <div class="md:flex md:items-center mb-6">
      <div class="md:w-1/3">
        <label
          class="block text-baton-gray font-bold md:text-right mb-1 md:mb-0 pr-4"
          for="inline-full-name">
          Full Name
        </label>
      </div>
      <div class="md:w-2/3">
        <input
          bind:value={name}
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
          w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
          focus:bg-white focus:border-purple-500"
          id="inline-full-name"
          type="text"
          placeholder="Your Name"
          required />
      </div>
    </div>
    <div class="md:flex md:items-center mb-6">
      <div class="md:w-1/3">
        <label
          class="block text-baton-gray font-bold md:text-right mb-1 md:mb-0 pr-4"
          for="inline-email">
          Email Address
        </label>
      </div>
      <div class="md:w-2/3">
        <input
          bind:value={email}
          class="bg-gray-200 appearance-none border-2 border-gray-200 rounded
          w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none
          focus:bg-white focus:border-baton-gray"
          id="inline-email"
          type="text"
          placeholder="name@domain.com"
          required />
      </div>
    </div>

    <div class="md:flex md:items-center mb-6">
      <div class="md:w-1/3">
        <label
          class="block text-baton-gray font-bold md:text-right mb-1 md:mb-0 pr-4"
          for="inline-message">
          Message
        </label>
      </div>
      <div class="md:w-2/3">
        <textarea
          bind:value={message}
          rows="5"
          class="resize-none bg-gray-200 appearance-none border-2
          border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight
          focus:outline-none focus:bg-white focus:border-baton-gray"
          id="inline-message"
          required />
      </div>
    </div>

    <div class="md:flex md:items-center mb-6" />

    <div class="md:flex md:items-center">
      <div class="md:w-1/3" />
      <div class="md:w-2/3">

        <button
          on:click={handleForm}
          class="shadow bg-baton-gray hover:bg-baton-red focus:shadow-outline
          focus:outline-none text-white font-bold py-2 px-4 rounded"
          type="button">
          Submit
        </button>
      </div>
    </div>
  </form>
{:else}
  <p class="text-baton-gray mb-4 text-xl">
    Thanks for your message, we will respond ASAP.
  </p>
{/if}
