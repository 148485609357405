import BetaForm from './BetaForm.svelte'
import GlobalNav from './GlobalNav.svelte'
import ContactForm from './ContactForm.svelte'

let betaForm = {}
let globalNav = {}
let contactForm = {}

window.addEventListener('DOMContentLoaded', (event) => {

    const betaTest = document.querySelector('#betaTestTrial') || null
    const contactTest = document.querySelector('#contactForm') || null
    const globalNavTest = document.querySelector('#globalNav') || null

    if(betaTest) {
        const betaForm = new BetaForm({
            target: document.querySelector('#betaTestTrial')
        })
        window.betaForm = betaForm
    } else {
        window.betaForm = {}
    }

    if(contactTest) {
        const contactForm = new ContactForm({
            target: document.querySelector('#contactForm')
        })
        window.contactForm = contactForm
    } else {
        window.contactForm = {}
    }

    if(globalNavTest) {
        const globalNav = new GlobalNav({
            target: document.querySelector('#globalNav'),
            props: {'loggedIn': (typeof user === "undefined" ) ? false : user.loggedIn}
        })
        window.globalNav = globalNav
    } else {
        window.globalNav = {}
    }

})


export default {
    betaForm,
    globalNav,
    contactForm
}