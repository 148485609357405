<script>
  let isOpen = false;
  export let loggedIn = false;

  $: navState = isOpen ? "block" : "hidden";

  function toggle() {
    isOpen = !isOpen;
  }
</script>

<!-- https://github.com/tailwindcss/designing-with-tailwindcss/blob/master/03-building-a-responsive-navbar/03-making-the-navbar-responsive/src/components/Navbar.vue -->
<header class="sm:flex sm:justify-between sm:items-center px-4 py-3">
  <div class="flex items-center justify-between sm:mb-0">
    <div class="">
      <a href="/" class="logo">Baton</a>
    </div>
    <div class="sm:hidden">
      <button
        on:click={toggle}
        type="button"
        class="hover:text-gray-500 focus:text-gray-500 focus:outline-none">
        <svg class="h-12 w-12 fill-current" viewBox="0 0 24 24">
          {#if isOpen}
            <path
              fill-rule="evenodd"
              d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1
              1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1
              1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828
              4.829 4.828 4.828z" />
          {:else}
            <path
              fill-rule="evenodd"
              d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1
              1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
          {/if}
        </svg>
      </button>
    </div>
  </div>
  <nav class="{navState} sm:flex text-lg">
    <a
      on:click={toggle}
      href="/faq"
      class="block px-2 py-1 mb-1 text-right md:text-baton-gray
      md:hover:text-baton-red bg-baton-blue md:bg-transparent">
      FAQs.
    </a>
    <a
      on:click={toggle}
      href="/demo"
      class="block px-2 py-1 mb-1 text-right md:text-baton-gray
      md:hover:text-baton-red bg-baton-blue md:bg-transparent">
      Product demo.
    </a>
    <a
      on:click={toggle}
      href="/get-started"
      class="block px-2 py-1 mb-1 text-right md:text-baton-gray
      md:hover:text-baton-red bg-baton-blue md:bg-transparent">
      Get Started.
    </a>
    <a
      on:click={toggle}
      href="/contact-us"
      class="block px-2 py-1 mb-1 text-right md:text-baton-gray
      md:hover:text-baton-red bg-baton-blue md:bg-transparent">
      Contact us.
    </a>
    {#if loggedIn === 'true'}
      <a
        on:click={toggle}
        href="/account"
        class="block px-2 py-1 mb-1 text-right md:text-baton-gray
        md:hover:text-baton-red bg-baton-blue md:bg-transparent">
        Account.
      </a>
      <a
        on:click={toggle}
        href="/logout"
        class="block px-2 py-1 mb-1 text-right md:text-baton-gray
        md:hover:text-baton-red bg-baton-blue md:bg-transparent">
        Logout.
      </a>
    {:else}
      <a
        on:click={toggle}
        href="/login"
        class="block px-2 py-1 mb-1 text-right md:text-baton-gray
        md:hover:text-baton-red bg-baton-blue md:bg-transparent">
        Login/Signup.
      </a>
    {/if}

  </nav>
</header>
