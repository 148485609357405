<script>
  let email = "";
  let submit = false;
  let formErrors = false;
  let serverErrors = false;

  function handleForm() {
    let myEmail = email;
    let emailValid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
      myEmail
    );

    if (emailValid) {
      // email is valid, so send the data to the server
      let route = "/signup";
      let data = {
        email: myEmail,
        key: "1b0e1522-361d-454d-bd72-9942fcfc54f0" // this is my security for the AJAX form. the route is expecting this.
      };

      fetch(route, {
        method: "POST",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          // submit is true and UI will update automatically
          submit = true;
          // fire custom GA event for successful form submit
          dataLayer.push({ event: "formSubmitted", formName: "Beta Signup" });
        })
        .catch(error => {
          serverErrors = true;
          submit = false;
        });
    } else {
      // they didn't supply an email or their email is not in a valid format
      formErrors = true;
      submit = false;
    }
  }
</script>

<div id="early-access" />
<section class="text-center container mx-auto mb-10 lg:mb-20 py-10 text-white">
  <h3 class="text-3xl mb-6">Sign up for early access.</h3>
  {#if !submit}
    <p class="text-lg">
      Baton is in early beta. Sign up below to request becoming a beta tester.
    </p>
    {#if formErrors}
      <p class="text-baton-red mb-4 text-lg">
        Please provide a valid email address.
      </p>
    {/if}
    {#if serverErrors}
      <p class="text-baton-red mb-4 text-lg">
        We are experiencing server errors. Please try again or shoot us an
        email. Sorry for the hassle.
      </p>
    {/if}
    <form action="#" on:submit|preventDefault class="mt-8">
      <input
        bind:value={email}
        type="text"
        class="bg-white text-baton-gray w-48 md:w-56 focus:outline-none
        focus:shadow-outline border border-gray-300 rounded-md py-2 px-4
        inline-block appearance-none leading-normal"
        placeholder="name@email.com" />
      <button
        on:click={handleForm}
        id="request_access"
        class="inline-block rounded-md border-baton-red bg-baton-red text-white
        hover:text-baton-gray px-6 py-2 uppercase leading-normal">
        Request Access
      </button>
      <p class="pt-4 text-sm">
        We will not share your email address with anyone.
      </p>
    </form>
  {:else}
    <p class="text-baton-red mb-4 text-xl">
      Thanks for signing up! We will be in touch with next steps.
    </p>
  {/if}

</section>
